import 'lodash-prototypes';
import '../styles/defaults.scss';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { UserProvider } from '@auth0/nextjs-auth0';
// import Datadog from "react-datadog";
import { hotjar } from 'react-hotjar';
import { useEffect } from 'react';


export default function Flapjack({ Component, pageProps }: AppProps) {
    useEffect(() => {
        if(window.location.host !== 'app.beta.flapjack.co' && window.location.host !== 'localhost:3000')
            hotjar.initialize(3202680, 6)
      }, [])
      
    return (
        // <Datadog applicationId={'d902299327ce72cc84d1d2e14b525fd84a761f69'}
        //          clientToken={'pubef7a4c673d1d5a5a4094a446b159f9fc'}
        //          service="app.beta.flapjack.co"
        //          sessionReplayRecording>
            <UserProvider loginUrl={'/api/login'} profileUrl={'/api/me'} >
                <Component {...pageProps} />
            </UserProvider>
        // </Datadog>
    );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
    console.log(metric)
}
